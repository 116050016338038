<template>
  <form
    @submit.prevent="handleSubmit"
    class="p-2 container"
    v-shortkey="saveShortkey"
    @shortkey="handleSubmit"
  >
    <div class="form-row">
      <h4>Billing Transaction Code</h4>
    </div>
    <div class="form-row">
      <TextInput
        v-focus
        class="col"
        v-model="txCode.code"
        label="Code"
        maxLength="16"
        :validator="$v.txCode.code"
      />
      <TextInput
        maxLength="21"
        class="col"
        v-model="txCode.alias"
        label="Alias"
        :validator="$v.txCode.alias"
      />
    </div>
    <div class="form-row">
      <DatePicker
        class="col"
        v-model="txCode.effectiveOn"
        label="Effective On"
        :validator="$v.txCode.effectiveOn"
      />
      <DatePicker
        class="col"
        v-model="txCode.expiryOn"
        label="Expiry"
        :min="txCode.effectiveOn"
        :validator="$v.txCode.expiryOn"
      />
    </div>
    <div class="form-row">
      <TextAreaInput
        class="col"
        rows="2"
        :resize="false"
        maxLength="51"
        v-model="txCode.description"
        :validator="$v.txCode.description"
        label="Description"
      />
      <fieldset class="col">
        <legend v-if="txCode.billingTransactionCodeTypeId === 1">Debit</legend>
        <SelectInput
          class="flex-fill"
          :items="transactionCodeTypes"
          v-model="txCode.billingTransactionCodeTypeId"
          :validator="$v.txCode.billingTransactionCodeTypeId"
          label="Type"
        />
        <Checkbox
          id="diagReq"
          class="mt-2"
          v-model="txCode.isDiagnosisRequired"
          label="Diagnosis Required"
        />
        <Checkbox id="amtReq" v-model="txCode.isAmountRequired" label="Amount Required" />
      </fieldset>
    </div>
    <div>
      <div class="form-row justify-content-between">
        <h4>Billing Rates</h4>
        <AddBtn type="button" class="align-self-end mb-2" @click="addRate" />
      </div>
      <PropTable
        ref="rateGrid"
        @edit="editRate"
        @remove="removeSpecimenRate"
        v-model="allSpecimenRates"
        :apiRemove="true"
        class="w-100"
        :columns="columns"
        noDataText="No rates found."
      />
      <div v-if="$v.txCode.specimenRates.$invalid">
        <span class="text-muted" v-if="$v.txCode.specimenRates.minLength">
          Please add at least one rate.
        </span>
      </div>
    </div>
    <div class="mt-2 d-flex justify-content-end">
      <button type="button" class="btn btn-danger mx-2" @click="updateView">Cancel</button>
      <button type="submit" :disabled="$v.$invalid" class="btn btn-primary align-self-end">
        Submit
      </button>
    </div>
    <Modal :status="isModalOpen" @close="closeModal">
      <SpecimenRate v-model="selectedRate" @close="closeModal" @save="handleSaveRate" />
    </Modal>
  </form>
</template>
<script>
import PropTable from "@/components/common/PropTable.vue";
import TextAreaInput from "@/components/TextAreaInput.vue";
import Modal from "@/components/common/Modal.vue";
import SpecimenRate from "./SpecimenRate.vue";
import BillingApi from "@/services/Billing";
import DropdownApi from "@/services/dropdown";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import moment from "moment";
import auditLog from "@/services/AuditLog";
import { altKey, createLogItem, oneYearAgo } from "@/modules/helpers";
import { createLogComment } from "@/modules/helpers";
import { cloneDeep } from "lodash";
import AddBtn from "@/components/common/AddButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import DatePicker from "@/components/common/DatePicker.vue";

export default {
  name: "TransactionCode",
  components: {
    PropTable,
    TextAreaInput,
    SpecimenRate,
    Modal,
    AddBtn,
    TextInput,
    SelectInput,
    Checkbox,
    DatePicker
  },
  props: ["transactionId"],
  mounted() {
    if (this.transactionId) {
      this.isEditing = true;
      BillingApi.getTransactionCode(this.transactionId).then(res => {
        this.txCode = { ...res };
        this.originalTx = cloneDeep({
          ...res,
          specimenRates: [...res.specimenRates.map(e => ({ ...e }))]
        });
      });
      auditLog.insertLogMessage({
        ...createLogItem({}, 7),
        comments: `Viewed transaction code ${this.transactionId}`
      });
    }
    DropdownApi.getTransactionCodeTypes().then(res => {
      this.transactionCodeTypes = res || [];
    });
  },
  data() {
    return {
      transactionCodeTypes: [],
      isEditing: false,
      isModalOpen: false,
      originalTx: {},
      isModalEditing: false,
      columns: [
        {
          dataField: "num",
          allowSorting: false
        },
        { dataField: "description", allowSorting: false }
      ],
      txCode: {
        code: "",
        alias: "",
        description: "",
        billingTransactionCodeTypeId: 1,
        isDiagnosisRequired: false,
        isAmountRequired: false,
        effectiveOn: oneYearAgo(),
        expiryOn: null,
        specimenRates: []
      },
      defaultTxCode: {
        code: "",
        alias: "",
        description: "",
        billingTransactionCodeTypeId: 1,
        isDiagnosisRequired: false,
        isAmountRequired: false,
        effectiveOn: oneYearAgo(),
        expiryOn: null,
        specimenRates: []
      },
      selectedRateIndex: null,
      selectedRate: null,
      saveShortkey: altKey("s")
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    allSpecimenRates() {
      return this.txCode.specimenRates.filter(e => !e.isDeleted);
    }
  },
  validations() {
    return {
      txCode: {
        code: {
          required,
          maxLength: maxLength(15)
        },
        alias: {
          maxLength: maxLength(20)
        },
        description: {
          required,
          maxLength: maxLength(50)
        },
        billingTransactionCodeTypeId: {
          required
        },
        specimenRates: {
          required,
          minLength: minLength(1)
        },
        effectiveOn: {
          required
        },
        expiryOn: {
          afterEffective: function (value) {
            if (value != null && value != undefined && moment(value).isValid()) {
              return moment(value).isAfter(this.txCode.effectiveOn);
            }
            return true;
          }
        }
      }
    };
  },
  methods: {
    handleSubmit() {
      const submissionCall = ({ validationErrors, id }) => {
        if (!validationErrors.length) {
          if (JSON.stringify(this.originalTx) !== JSON.stringify(this.txCode)) {
            let logItem;
            if (this.isEditing) {
              logItem = createLogItem({}, 5);
              logItem.comments = `${this.originalTx.id}:${createLogComment(
                this.originalTx,
                this.txCode
              )}`;
            } else {
              logItem = createLogItem({}, 4);
              logItem.comments = "Created a new transaction code.";
              logItem.pageOrGridViewed = logItem.pageOrGridViewed.replace("new", id);
            }
            auditLog.insertLogMessage(logItem);
          }
          return this.updateView();
        } else {
          const message = validationErrors.join("\n");
          alert(message);
        }
      };
      if (this.isEditing) {
        return BillingApi.updateTransactionCode({
          ...this.txCode,
          labId: this.currentLab
        }).then(submissionCall);
      } else {
        return BillingApi.addTransactionCode({
          ...this.txCode,
          labId: this.currentLab
        }).then(submissionCall);
      }
    },
    async removeSpecimenRate({ data, rowIndex }) {
      const dialogueResponse = await window.confirm(
        "This action is irreversible.\nAre you sure you want to continue?",
        "Billing Rates"
      );
      if (!dialogueResponse) {
        return;
      }
      if (!data.isNew) {
        return (data.isDeleted = true);
      } else {
        return this.txCode.specimenRates.splice(rowIndex, 1);
      }
    },
    openModal() {
      this.isModalOpen = true;
    },
    addRate() {
      this.selectedRate = {
        billingRateId: "",
        rateTypeId: "",
        description: "",
        pricePoints: [],
        isNew: true
      };
      this.isModalEditing = false;
      return this.openModal();
    },
    editRate({ data }) {
      this.isModalEditing = true;
      this.selectedRate = data;
      return this.openModal();
    },
    closeModal() {
      this.selectedRate = null;
      this.selectedRateIndex = null;
      this.isAdding = false;
      this.isModalOpen = false;
      this.isModalEditing = false;
    },
    updateView() {
      return this.$emit("close");
    },
    handleSaveRate(rate) {
      if (this.isModalEditing) {
        return;
      } else {
        const hasBillingRate = this.txCode.specimenRates.some(
          e => e.billingRateId === rate.billingRateId
        );
        if (hasBillingRate) {
          alert("WARNING: You have added a specimen rate, with a duplicate billing rate.");
        }
      }
      return this.txCode.specimenRates.push(rate);
    }
  }
};
</script>

<style lang="scss" scoped>
.col {
  margin: 8px 0;
}
</style>
