<template>
  <container class="p-1">
    <div class="d-flex flex-column">
      <DxGridWithSearch
        class="my-2"
        title="Billing Transaction Code Maintenance"
        refKey="TxCodesGrid"
        :newSearch="true"
        :dataSource="billingStore"
        :columns="columns"
        ref="txCodesGrid"
        noDataText="No transaction codes found."
        :pageSize="12"
        :columnResize="true"
        :toolbar="toolbar"
        @initialized="initializeGrid"
      >
        <template v-slot:extraActions>
          <add-button
            type="button"
            @click="createTransaction"
            v-if="permissions.TransactionCodeCreateEdit"
          />
        </template>
        <template v-slot:actions="{ data }">
          <icon-button
            @click="editTransactionCode(data)"
            class="text-primary pointer p-0"
            icon="pen-alt"
          />
        </template>
        <template v-slot:expirationCell="{ data }">
          <ExpiryTemplate :expiry-date="data.value" />
        </template>
      </DxGridWithSearch>
    </div>
    <modal :status="currentView" @close="closeTransaction">
      <transaction-code :transactionId="selectedTxId" @close="closeTransaction" />
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "@/components/common/DxGridWithSearch";
import { mapState, mapGetters } from "vuex";
import DropdownApi from "@/services/dropdown";
import auditLog from "@/services/AuditLog";
import { createLogItem } from "@/modules/helpers";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import { BillingApi } from "@/services";
import Container from "../../common/Container.vue";

import TransactionCode from "./TransactionCode.vue";
import Modal from "@/components/common/Modal.vue";
import DataSource from "devextreme/data/data_source";
import IconButton from "@/components/common/IconButton.vue";
import AddButton from "@/components/common/AddButton.vue";

export default {
  name: "BillingTransactions",
  metaInfo: {
    title: "Billing Transaction Code Maintenance",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    DxGridWithSearch,
    ExpiryTemplate,
    Container,
    TransactionCode,
    Modal,
    IconButton,
    AddButton
  },
  data() {
    return {
      grid: {},
      transactionCodeTypes: [],
      billingStore: new DataSource({ store: BillingApi.searchStore }),
      selectedTxId: null,
      currentView: false
    };
  },
  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin billing page."
    });
    DropdownApi.getTransactionCodeTypes().then(res => {
      this.transactionCodeTypes = res || [];
    });
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapGetters(["permissions"]),

    fixedFilters() {
      return [
        {
          column: "labId",
          operator: "=",
          value: this.currentLab
        }
      ];
    },
    columns() {
      return [
        {
          dataField: "description",
          sortIndex: 0,
          sortOrder: "asc",
          filterField: "Description"
        },
        {
          dataField: "code"
        },
        {
          dataField: "billingTransactionCodeTypeId",
          caption: "Transaction Type",
          calculateCellValue({ displayName }) {
            return displayName;
          },
          headerFilter: {
            dataSource: this.transactionCodeTypes.map(e => {
              return {
                text: e.displayName,
                value: ["BillingTxnCodeTypeId", "=", e.id]
              };
            })
          }
        },
        {
          dataField: "effectiveOn",
          caption: "Effective",
          filterField: "EffectiveOn",
          dataType: "date"
        },
        {
          dataField: "expiryOn",
          caption: "Expiration",
          dataType: "date",
          filterField: "ExpiryOn",
          cssClass: "p-0",
          cellTemplate: "expirationCell"
        },
        {
          caption: "Actions",
          type: "buttons",
          cellTemplate: "actions",
          alignment: "center",
          allowFiltering: false,
          allowSorting: false,
          visible: this.permissions.TransactionCodeCreateEdit
        },
        {
          dataField: "labId",
          visible: false
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            template: "extraActions",
            locateInMenu: "auto"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    }
  },
  watch: {
    currentLab(nv, ov) {
      if (nv != ov) {
        const grid = this.$refs?.txCodesGrid?.$refs?.grid?.instance;
        if (grid) {
          grid.filter(["labId", "=", nv]);
        }
      }
    }
  },
  methods: {
    initializeGrid(event) {
      this.grid = event.component;
    },
    editTransactionCode(row) {
      this.selectedTxId = row.key;
      this.currentView = true;
    },
    closeTransaction() {
      this.selectedTxId = null;
      this.currentView = false;
      this.billingStore.reload();
    },
    createTransaction() {
      this.selectedTxId = null;
      this.currentView = true;
    },
    navigate(row) {
      if (row) {
        return this.$router.push({
          path: "/admin/billing/transactions/" + row?.data?.id
        });
      }
      return this.$router.push({
        path: "/admin/billing/transactions/new"
      });
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    }
  }
};
</script>

<style lang="scss" scoped></style>
