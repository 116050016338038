<template>
  <form
    @submit.prevent="handleSubmit"
    class="bg-white specimen_form container"
    v-shortkey="saveShortkey"
    @shortkey="handleSubmit"
  >
    <h5>Specimen Rate</h5>
    <div class="my-3" action="">
      <SelectInput
        :items="billingRates"
        class="mb-3"
        v-model="specimenRate.billingRateId"
        label="Billing Rate"
      />
      <SelectInput :items="rateTypes" v-model="specimenRate.rateTypeId" label="Rate Type" />
    </div>
    <div class="d-flex align-items-center" v-if="specimenRate.rateTypeId === 2">
      <div>
        <span class="text-muted" v-if="!allPricePoints.length">
          Please add at least one price point.
        </span>
      </div>
      <AddBtn
        type="button"
        :text="isFormOpen ? 'Close' : 'Add'"
        :icon="isFormOpen ? 'times' : 'plus'"
        class="my-1 ml-auto"
        @click="handleAddClick"
      />
    </div>
    <div>
      <form
        @submit.prevent="insertNewPricePoint(pricePoint)"
        v-if="isFormOpen && isAddingPricePoint"
        class="d-flex flex-column"
      >
        <div class="d-flex">
          <TextInput
            class="mr-1 hide_arrows"
            type="number"
            v-model="pricePoint.minQty"
            label="MinQty"
            :validator="$v.pricePoint.minQty"
          />
          <CurrencyInput
            class="ml-1 hide_arrows"
            type="number"
            v-model="pricePoint.unitPrice"
            label="Unit Price"
            :validator="$v.pricePoint.unitPrice"
          />
        </div>
        <button type="submit" class="btn btn-primary my-2 align-self-end">Save</button>
      </form>
    </div>
    <dx-data-grid
      :keyboardNavigation="{
        editOnKeyPress: true,
        enterKeyDirection: 'startEdit'
      }"
      v-if="specimenRate.rateTypeId === 2"
      v-show="allPricePoints.length"
      @keypress.native.enter.prevent
      ref="rateGrid"
      class="w-100"
      :columns="columns"
      :data-source="allPricePoints"
      noDataText="No rates found."
      :editing="editing"
      :cacheEnabled="false"
    >
      <template v-slot:edit="{ data }">
        <icon-button
          type="button"
          icon="pen-alt"
          class="btn text-primary"
          @click="editPricePoint(data)"
        />
      </template>

      <template v-slot:delete="{ data }">
        <icon-button
          type="button"
          icon="trash-alt"
          class="btn text-danger"
          @click="removePricePoint(data)"
        />
      </template>

      <template v-slot:save="{ data }">
        <icon-button
          ref="saveBtn"
          icon="save"
          type="button"
          class="btn text-primary"
          @click="saveEdit(data)"
        />
      </template>

      <template v-slot:cancel>
        <icon-button type="button" icon="times" class="btn text-danger" @click="cancelEdit" />
      </template>

      <template v-slot:editInput="{ data }">
        <input
          class="edit_input"
          @keydown.enter.prevent="handleEnterBtn"
          @keydown.69.prevent
          type="number"
          max="999"
          :name="data.column.dataField"
          :data-row-index="data.rowIndex"
          :noLabel="true"
          :value="data.value"
          @change="data.setValue(Number($event.target.value))"
          @input="data.setValue(Number($event.target.value))"
        />
      </template>
    </dx-data-grid>
    <CurrencyInput
      v-else-if="specimenRate.rateTypeId"
      v-model="specimenRate.pricePoints[0].unitPrice"
      label="Unit Price"
    />

    <div class="my-2 d-flex justify-content-end">
      <button :disabled="$v.$invalid" class="btn btn-primary ml-auto" type="submit">Submit</button>
    </div>
  </form>
</template>

<script>
import { DxDataGrid } from "devextreme-vue/data-grid";
import DropdownApi from "@/services/dropdown";
import { required, minValue, minLength } from "vuelidate/lib/validators";
import CurrencyInput from "@/components/CurrencyInput.vue";
import IconButton from "@/components/common/IconButton.vue";
import AddBtn from "@/components/common/AddButton.vue";
import TextInput from "@/components/common/TextInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import { altKey } from "@/modules/helpers";

export default {
  name: "SpecimenRate",
  props: {
    value: {
      required: true
    }
  },
  computed: {
    specimenRate: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    allPricePoints: {
      get() {
        return this.value?.pricePoints.filter(e => !e.isDeleted) || [];
      }
    },
    grid() {
      return this.$refs?.rateGrid?.instance;
    }
  },
  components: {
    DxDataGrid,
    CurrencyInput,
    IconButton,
    AddBtn,
    TextInput,
    SelectInput
  },
  validations: {
    pricePoint: {
      minQty: {
        minValue: minValue(1)
      },
      unitPrice: { minValue: minValue(1) }
    },
    value: {
      billingRateId: {
        required
      },
      rateTypeId: {
        required
      },
      pricePoints: {
        required,
        minLength: minLength(1),
        $each: {
          minQty: {
            minValue: minValue(1)
          },
          unitPrice: { minValue: minValue(1) }
        }
      }
    }
  },
  watch: {
    "value.billingRateId": function (nv, ov) {
      if (nv != ov) {
        const billingRate = this.billingRates.find(e => e.id === nv);
        if (billingRate) {
          const { description, num } = billingRate;
          this.specimenRate.description = description;
          this.specimenRate.num = num;
        }
      }
    },
    "value.rateTypeId"(nv, ov) {
      if (nv != ov) {
        if (nv === 1) {
          this.isAddingPricePoint = false;
          if (this.specimenRate.pricePoints.length > 0) {
            this.specimenRate.pricePoints.forEach((e, i) => {
              if (i > 0) {
                e.isDeleted = true;
              }
            });
          } else {
            return (this.specimenRate.pricePoints = [
              {
                minQty: 1,
                unitPrice: 1
              }
            ]);
          }
        } else {
          if (this.specimenRate.pricePoints.length > 1) {
            this.specimenRate.pricePoints.forEach((e, i) => {
              if (i > 0) {
                e.isDeleted = false;
              }
            });
          }
        }
      }
    }
  },
  data() {
    return {
      isAdding: false,
      isFormOpen: false,
      isAddingPricePoint: false,
      billingRate: null,
      pricePoint: {
        minQty: 1,
        unitPrice: 1,
        isNew: true
      },
      editing: {
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: false,
        seletTextOnEditStart: true,
        mode: "row"
      },
      columns: [
        {
          dataField: "minQty",
          dataType: "number",
          editCellTemplate: "editInput"
        },
        {
          dataField: "unitPrice",
          dataType: "number",
          format: "currency",
          editCellTemplate: "editInput"
        },
        {
          caption: "Actions",
          type: "buttons",
          buttons: [
            {
              name: "edit",
              template: "edit"
            },
            {
              name: "save",
              template: "save"
            },
            {
              name: "cancel",
              template: "cancel"
            },
            {
              name: "delete",
              template: "delete"
            }
          ]
        }
      ],
      billingRates: [],
      rateTypes: [],
      saveShortkey: altKey("s")
    };
  },
  mounted() {
    DropdownApi.getBillingRates().then(res => {
      this.billingRates = res || [];
    });
    DropdownApi.getRateTypes().then(res => {
      this.rateTypes = res || [];
    });
  },
  methods: {
    handleEnterBtn(event) {
      const { target } = event;
      const { value, dataset, name } = target;
      const { rowIndex } = dataset;
      if (rowIndex != undefined) {
        const pricePoint = {
          ...this.value.pricePoints[rowIndex],
          [name]: Number(value)
        };
        if (
          this.value.pricePoints[rowIndex].minQty.toString() === pricePoint.minQty.toString() &&
          Number(pricePoint.unitPrice) > 0
        ) {
          return this.grid.saveEditData();
        }
        if (this.validatePricePoint(pricePoint)) {
          if (Number(value) != this.value.pricePoints[rowIndex][name]) {
            this.value.pricePoints[rowIndex][name] = Number(value);
            return this.grid.saveEditData();
          }
        }
      }
    },
    saveEdit({ data, values }) {
      if (data.minQty.toString() === values[0].toString() && values[1] > 0) {
        return this.grid.saveEditData();
      }
      if (this.validatePricePoint({ minQty: values[0], unitPrice: values[1] })) {
        return this.grid.saveEditData();
      }
    },
    handleSubmit() {
      this.$emit("save", this.specimenRate);
      this.$emit("close");
    },
    editPricePoint({ rowIndex }) {
      this.grid?.editRow(rowIndex);
    },
    validatePricePoint(pricePoint) {
      const { minQty, unitPrice } = pricePoint;
      if (minQty < 1 || minQty > 999) {
        alert("Invalid quantity value.");
        return false;
      }
      if (unitPrice < 1) {
        alert("Invalid unit price value.");
        return false;
      }
      const setOfMinQty = new Set(
        this.allPricePoints.map(e => e.minQty.toString()).filter(e => !e.isDeleted)
      );
      if (setOfMinQty.has(minQty.toString())) {
        alert("Error a price point for this quantity already exists. \nQuantity: " + minQty);
        return false;
      }
      if (!unitPrice) {
        alert("Please enter a unit price.");
        return false;
      }
      return true;
    },
    insertNewPricePoint(pricePoint) {
      const isValid = this.validatePricePoint(pricePoint);
      if (isValid) {
        this.specimenRate.pricePoints = [...this.value.pricePoints, pricePoint];
        return this.handleAddClick();
      }
    },
    handleAddClick() {
      this.pricePoint = {
        minQty: "",
        unitPrice: 1,
        isNew: true
      };
      this.isAddingPricePoint = true;
      if (!this.isFormOpen) {
        return (this.isFormOpen = true);
      }
      return (this.isFormOpen = false);
    },
    removePricePoint({ data }) {
      if (!data.isNew) {
        return (data.isDeleted = true);
      }
      if (data.isNew) {
        const pricePointIndex = this.specimenRate.pricePoints.findIndex(
          e => e.minQty.toString() === data.minQty.toString()
        );
        if (pricePointIndex != undefined) {
          this.specimenRate.pricePoints = [
            ...this.value.pricePoints.slice(0, pricePointIndex),
            ...this.value.pricePoints.slice(pricePointIndex + 1)
          ];
        }
      }
    },
    cancelEdit() {
      return this.grid?.cancelEditData();
    }
  }
};
</script>
<style lang="scss" scoped>
.specimen_form {
  width: 400px;
}
.edit_input {
  border: none;
  margin: auto;
  height: 80%;
  width: 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
  -moz-appearance: textfield;
}
</style>
